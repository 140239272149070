@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --font-family-open-sans-serif: "Open Sans", sans-serif;
  --font-family-open: var(--font-family-open-sans-serif);
  --font-family-rubic-sans-serif: "Rubik", sans-serif;
  --font-family-rubic: var(--font-family-rubic-sans-serif);
  --font-size-base: 1rem;
}

.font-open {
  font-family: var(--font-family-open);
}
.font-rubic {
  font-family: var(--font-family-rubic);
}

// Thin
.fw-100 {
  font-weight: 100;
}
// Light 300
.fw-300 {
  font-weight: 300;
}
// Regular 400
.fw-400 {
  font-weight: 400;
}
// Medium 500
.fw-500 {
  font-weight: 500;
}
// Semi-bold 600
.fw-600 {
  font-weight: 600;
}
// Bold 700
.fw-700 {
  font-weight: 700;
}
// Extra-bold 800
.fw-800 {
  font-weight: 800;
}
// Black 900
.fw-900 {
  font-weight: 900;
}

/* ============================================== Font Sizes ===================================================== */

.fs-8 {
  font-size: 8px;
}
.fs-9 {
  font-size: 9px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-25 {
  font-size: 25px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}
.fs-29 {
  font-size: 29px;
}
.fs-30 {
  font-size: 30px;
}
.fs-31 {
  font-size: 31px;
}
.fs-32 {
  font-size: 32px;
}

@media (max-width: 991.98px) {
}
@media (max-width: 1199.98px) {
}
@media (max-width: 767.98px) {
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~bootstrap/scss/bootstrap";
@import "palette";
@import "typography";
@import "variables";
@import "mixins";
@import "buttons";
@import "custom";
@import "admin";

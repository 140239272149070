html {
  --color-white: #ffffff;
  --color-titan-white: #f0efff;
  --color-black: #000000;
  --color-smoky-black: #0d0e08;
  --color-shark: #191a1f;
  --color-royal-blue: #2a41e8;
  --color-royal-blue-dark: #06189f;
  --color-royal-blue-dark2: #3c5fb2;
  --color-blue-ribbon: #0d50eb;
  --color-blue-ribbon-2: #f2f4fd;
  --color-zircon: #f1f3ff;
  --color-river-bed: #4d5969;
  --color-gray: #808080;
  --color-gray2: #929292;
  --color-dove-gray: #707070;
  --color-code-gray2: #1d1d1d;
  --color-code-gray3: #111111;
  --color-code-gray4: #1c1c1c;
  --color-code-gray5: #151515;
  --color-dusty-gary: #989898;
  --color-dusty-gary2: #999999;
  --color-silver-chalice: #b0b0b0;
  --color-concrete: #f3f3f3;
  --color-amaranth: #eb1d63;
  --color-orange: #ff5e14;
  --color-mine-shaft: #292929;
  --color-Sazerac: #fff3e0;
  --color-tuft-bush: #ffd1c4;
  --color-my-sin: #ffb623;
  --color-river-bed: #4c5369;
  --color-midnight: #000929;
  --color-iron: #e0e2e3;
  --color-tundora: #414141;
  --color-zumthor: #e4eaff;
  --color-periwinkle: #becdff;
  --color-alto: #d9d9d9;
  --color-White-Lilac: #f4f6fb;
  --color-White-Lilac2: #f9fafd;
  --color-gallery: #ebebeb;
  --color-emperor: #505050;
  --color-downriver: #091e42;
  --color-titan-white: #e6ebff;
  --color-gallery: #efefef;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

.bg-lithepink {
  background-color: rgb(249, 182, 182);
}

.text-smoky-black {
  color: var(--color-smoky-black);
}
.text-shark {
  color: var(--color-shark);
}
.text-royal-blue {
  color: var(--color-royal-blue);
}
.text-blue-ribbon {
  color: var(--color-blue-ribbon);
}
.text-zircon {
  color: var(--color-zircon);
}
.text-gray {
  color: var(--color-gray);
}
.text-gray2 {
  color: var(--color-gray2);
}
.text-dove-gray {
  color: var(--color-dove-gray);
}
.text-code-gray2 {
  color: var(--color-code-gray2);
}
.text-code-gray3 {
  color: var(--color-code-gray3);
}
.text-code-gray4 {
  color: var(--color-code-gray4);
}
.text-code-gray5 {
  color: var(--color-code-gray5);
}
.text-dusty-gary {
  color: var(--color-dusty-gary);
}
.text-dusty-gary2 {
  color: var(--color-dusty-gary2);
}
.text-silver-chalice {
  color: var(--color-silver-chalice);
}
.text-amaranth {
  color: var(--color-amaranth);
}
.text-orange {
  color: var(--color-orange);
}
.text-mine-shaft {
  color: var(--color-mine-shaft);
}

.text-river-bed {
  color: var(--color-river-bed);
}
.text-midnight {
  color: var(--color-midnight);
}
.text-tundora {
  color: var(--color-tundora);
}
.text-emperor {
  color: var(--color-emperor);
}
.text-downriver {
  color: var(--color-downriver);
}
.text-royal-blue-dark {
  color: var(--color-royal-blue-dark);
}
// Backgroun Colors

.bg-royal-blue {
  background-color: var(--color-royal-blue);
}
.bg-amaranth {
  background-color: var(--color-amaranth);
}
.bg-mine-shaft {
  background-color: var(--color-mine-shaft);
}
.bg-code-gray4 {
  background-color: var(--color-code-gray4);
}
.bg-code-gray5 {
  background-color: var(--color-code-gray5);
}
.bg-concrete {
  background-color: var(--color-concrete);
}
.bg-White-Lilac {
  background-color: var(--color-White-Lilac);
}
.bg-White-Lilac2 {
  background-color: var(--color-White-Lilac2);
}
.bg-blue-ribbon {
  background-color: var(--color-blue-ribbon);
}
.bg-blue-ribbon-2 {
  background-color: var(--color-blue-ribbon-2);
}
.bg-gray {
  background-color: var(--color-gray);
}
.bg-gallery {
  background-color: var(--color-gallery);
}
.text-justify {
  text-align: justify;
}
.shadow-lg-top {
}
.text-decoration-underline-none {
  text-decoration: none;
}

.bg-si {
  background-color: #f4f4f4;
}

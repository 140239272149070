@mixin login-sigup-btn {
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  border: 1px solid rgb(159, 159, 159);
  min-width: 100px;
  text-align: center;
  font-family: var(--font-family-open);
  font-size: 17px;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: var(--color-royal-blue);
    border: 1px solid var(--color-royal-blue);
  }
}
@mixin header-btn2 {
  padding: 0.5rem 1.5rem !important;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid rgb(159, 159, 159);
  border-radius: 20px;
  font-family: var(--font-family-open);
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: var(--color-white);
    border: 1px solid var(--color-royal-blue);
  }
}
@mixin btn-custom {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

@mixin abtn-primary-custom {
  @include btn-custom;
  background-color: var(--color-royal-blue);
  color: var(--color-white);
  &:hover,
  &:focus,
  &.active:focus-visible,
  &:focus-visible,
  &.show:focus-visible {
    color: var(--color-white);
    background-color: var(--color-royal-blue-dark);
  }
}

@mixin btn-semore {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-shark);
  border-color: var(--color-royal-blue);
  background-color: var(--color-white);
  text-transform: capitalize;
  &:hover,
  &:focus {
    background-color: var(--color-royal-blue);
    color: var(--color-white);
  }
}

@mixin btn-dark-custom {
  @include btn-custom;
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-black);
  padding: 0.5rem 4rem;
  border-radius: 3rem;
  &:hover,
  &:focus,
  &.active:focus-visible,
  &:focus-visible,
  &.show:focus-visible {
    border-color: var(--color-black);
    color: var(--color-black);
    background-color: var(--color-white);
  }
}

@mixin btn-light-custom {
  @include btn-custom;
  background-color: var(--color-white);
  color: var(--color-black);
  border-color: var(--color-white);
  padding: 0.5rem 4rem;
  border-radius: 3rem;
  &:hover,
  &:focus,
  &.active:focus-visible,
  &:focus-visible,
  &.show:focus-visible {
    border-color: var(--color-white);
    color: var(--color-white);
    background-color: var(--color-black);
  }
}
@mixin btn-light-custom {
  @include btn-custom;
  background-color: var(--color-white);
  color: var(--color-black);
  border-color: var(--color-white);
  padding: 0.5rem 4rem;
  border-radius: 3rem;
  &:hover,
  &:focus,
  &.active:focus-visible,
  &:focus-visible,
  &.show:focus-visible {
    border-color: var(--color-white);
    color: var(--color-white);
    background-color: var(--color-black);
  }
}
@mixin btn-primary-custom {
  @include btn-custom;
  background-color: var(--color-white);
  color: var(--color-royal-blue);
  border-color: var(--color-white);
  padding: 0.5rem 4rem;
  border-radius: 3rem;
  &:hover,
  &:focus,
  &.active:focus-visible,
  &:focus-visible,
  &.show:focus-visible {
    border-color: var(--color-white);
    color: var(--color-royal-blue);
    background-color: var(--color-white);
  }
}

@mixin btn-outline-secondary-custom {
  color: var(--color-dove-gray);
  border-color: var(--color-alto);
  font-size: 15px;
  font-family: var(--font-family-open);
  font-weight: 400;
  text-transform: capitalize;
  border-radius: 30px;
  padding: 0.3rem 1.5rem 0.3rem 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--color-white);
  height: 39px;
}
@mixin btn-outline-secondary-custom-hover {
  border-color: var(--color-periwinkle);
  background-color: var(--color-zumthor);
  color: var(--color-royal-blue);
  padding: 0.3rem 1.5rem 0.3rem 1rem;
}
@mixin form-control-input-custom {
  border-color: var(--color-alto);
  border-radius: 0.25rem;
  height: 46px;
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-family-open);
  color: var(--color-dove-gray);
  text-transform: capitalize;
}
textarea.form-control {
  height: auto;
}

@mixin card-hover-animation {
}
